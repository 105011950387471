import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const Caption = makeShortcode("Caption");
const Aside = makeShortcode("Aside");
const ResourceCard = makeShortcode("ResourceCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`We create beautiful mobile applications using latest hybrid technologies.`}</p>
    </PageDescription>
    <Row mdxType="Row">
      <Column colMd={5} colLg={8} mdxType="Column">
        <Row mdxType="Row">
  Magnus Code Mobile App Development helps you create high quality, customized
  and responsive mobile apps for multiple platforms.
        </Row>
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.52777777777779%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAIBAwQF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQAC/9oADAMBAAIQAxAAAAHlylznSIT/AP/EABwQAAEEAwEAAAAAAAAAAAAAAAEAAgMSBBETIv/aAAgBAQABBQJzaKhp0x0PR56dkxiOT//EABYRAAMAAAAAAAAAAAAAAAAAABARQf/aAAgBAwEBPwFwf//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAECAQE/AYf/xAAfEAACAQIHAAAAAAAAAAAAAAAAEQECEBIhMTJBcaH/2gAIAQEABj8CjpmJZGzy1L5FD0P/xAAdEAEAAgEFAQAAAAAAAAAAAAABABEhMUFRgZHw/9oACAEBAAE/IUVqbHqWLsXHBW37rAgLMehvBjo5T//aAAwDAQACAAMAAAAQjB//xAAXEQEBAQEAAAAAAAAAAAAAAAABABFB/9oACAEDAQE/EE6JsOX/xAAWEQEBAQAAAAAAAAAAAAAAAAARAAH/2gAIAQIBAT8QzQzf/8QAGxABAQADAQEBAAAAAAAAAAAAAREAITFBYdH/2gAIAQEAAT8QBi5GvBTIqPUjW5JbiOGAdOzeGK8gu4BiykM0bCn7jkwFZtqeHzP/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/028889d6e269e001840a060a1d0f8083/5f1c3/mobile-ui.webp 288w", "/static/028889d6e269e001840a060a1d0f8083/c4eb6/mobile-ui.webp 576w", "/static/028889d6e269e001840a060a1d0f8083/c2ad5/mobile-ui.webp 1152w", "/static/028889d6e269e001840a060a1d0f8083/8de58/mobile-ui.webp 1728w", "/static/028889d6e269e001840a060a1d0f8083/a2d2e/mobile-ui.webp 2304w", "/static/028889d6e269e001840a060a1d0f8083/55654/mobile-ui.webp 2800w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/028889d6e269e001840a060a1d0f8083/58ca1/mobile-ui.jpg 288w", "/static/028889d6e269e001840a060a1d0f8083/7fee0/mobile-ui.jpg 576w", "/static/028889d6e269e001840a060a1d0f8083/914fe/mobile-ui.jpg 1152w", "/static/028889d6e269e001840a060a1d0f8083/1889a/mobile-ui.jpg 1728w", "/static/028889d6e269e001840a060a1d0f8083/a9b74/mobile-ui.jpg 2304w", "/static/028889d6e269e001840a060a1d0f8083/91e15/mobile-ui.jpg 2800w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/028889d6e269e001840a060a1d0f8083/914fe/mobile-ui.jpg",
              "alt": "Mobile application development - Magnus Code - Malaysia",
              "title": "Mobile application development - Magnus Code - Malaysia",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span>
        <Caption fullWidth mdxType="Caption">UI/UX design and development</Caption>
        <Row mdxType="Row">
  Our in-house designing capability enables you to interact with our design
  experts and create your own unique application.
        </Row>
        <Row mdxType="Row">
  We offer comprehensive package from designing to launching and maintaining
  your apps.
        </Row>
        <p>{`Our focus on quality, functionality and security allows your organization to stand out and enhance your professional image. Magnus Code all-inclusive mobile app development solutions facilitate enhancing your app through every stage of the process.`}</p>
      </Column>
      <Column colMd={2} colLg={3} offsetMd={1} offsetLg={1} mdxType="Column">
        <Aside aria-label="Example aside" mdxType="Aside">
          <p><strong parentName="p">{`Mobile application for any use case.`}</strong></p>
          <p>{`We build beautiful, high performance and reliable enterprise hybrid mobile applications.`}</p>
        </Aside>
      </Column>
    </Row>
    <Row className="resource-card-group mt-4 " mdxType="Row">
  <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ResourceCard subTitle="Talk to our team" title="Contact Us" aspectRatio="2:1" actionIcon="arrowRight" href="/contact" color="light" className="blue-card" mdxType="ResourceCard"></ResourceCard>
  </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      